import React from 'react';
import { Link } from 'react-router-dom'; 
import './mobile.css';

const AboutUs = () => {
 

    return (
        <div>

            <section style={styles.hero}>

                <div class="header">
                    <div className="inner-header flex">
                        <h1 style={styles.herocontainerh1}>Features</h1>
                    </div>
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>


            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                            TurboMail combines ease of use with powerful functionality to help you achieve your email marketing goals. Whether you’re sending newsletters, promotional campaigns, or transactional emails, our platform offers a comprehensive suite of features tailored to meet your needs. Dive in to discover how TurboMail can enhance your communication strategy, boost your engagement, and drive results like never before. </p>
                    </div>
                </div>

            </section>

            <div className="mobileimage" style={styles.imageColumn}>
                        <img
                            src="/images/List-Management.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

            <section style={styles.section2}>
                <div className="container" style={styles.container}>
                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>List Management </h1>
                            <p style={styles.description}>

                                Efficient list management is crucial for any email marketing campaign, and TurboMail excels in this area. With our platform, you can easily create, organize, and segment your contact lists to ensure that your messages are targeted to the right audience.
                                <br />
                                <br />
                                In addition, our list hygiene features automatically detect and remove invalid or inactive email addresses, maintaining your sender reputation and improving deliverability rates.</p>
                        </div>
                    </div>
                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/List-Management.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>
            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                <img
                    src="/images/personalised-emailing.png"
                    alt="Intro-image"
                    style={styles.heroImage}
                />
            </div>

            <section style={styles.firstsection}>
                <div className="container" style={styles.container}>
                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/personalised-emailing.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div style={{ marginRight: '20px', marginLeft: '60px' }}>
                            <h1 style={styles.heading}>Personalised Emailing </h1>

                            <p style={styles.description}>Leverage the power of personalization with TurboMail’s dynamic content features. You can insert custom fields like names, locations, or previous purchase data into your emails, making them feel more relevant to your subscribers. </p>

                        </div>
                    </div>
                </div>
            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                        <img
                            src="/images/multi casting.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>Multicast </h1>
                            <p style={styles.description}>

                                With TurboMail’s multicast feature, you can send targeted messages to specific segments of your audience simultaneously, enhancing engagement and relevance. Unlike traditional bulk emails, multicast campaigns allow you to optimize send times and content based on audience preferences and behaviors.
                                <br />
                                <br />
                                This approach helps ensure that each group receives messages that are timely and tailored to their interests, leading to higher open and click-through rates.
                            </p>
                        </div>
                    </div>
                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/multi casting.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>
            </section>

            <section style={styles.firstsection}>
                <div className="container" style={styles.container}>
                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/List-Management.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>Real-Time Analytics </h1>
                            <p style={styles.description}>

                                Data is at the core of any successful marketing campaign, and TurboMail offers detailed real-time analytics to track the performance of your emails. You can monitor key metrics such as open rates, click-through rates, conversions, and more, allowing you to fine-tune your strategy on the go.
                                <br />
                                <br />
                                Visual reports and insights also help you understand audience behavior, enabling smarter targeting and better results.
                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <div className="mobileimage" style={styles.imageColumn}>
                        <img
                            src="/images/email-automation.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>
                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>Advanced Automation </h1>
                            <p style={styles.description}>

                                With TurboMail’s automation features, you can set up automated workflows to nurture leads and re-engage customers. Triggered emails based on user actions, such as abandoned cart reminders, welcome series, or post-purchase follow-ups, ensure timely and relevant communication.
                                <br />
                                <br />
                                By automating repetitive tasks, you save time while increasing efficiency and engagement.
                            </p>
                        </div>
                    </div>
                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/email-automation.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>
            </section>

            <section style={styles.firstsection}>
                <div className="container" style={styles.container}>
                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/Drag-and-drop.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>Drag-and-Drop Email Builder </h1>
                            <p style={styles.description}>

                                TurboMail’s intuitive drag-and-drop email builder allows you to create stunning, professional emails without any coding knowledge. You can choose from pre-designed templates or customize your own, adding images, buttons, and dynamic content blocks.
                                <br />
                                <br />

                                This visual editor empowers you to experiment with layouts and designs, ensuring your emails align with your brand identity and look great on all devices.  <br />

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <div className="mobileimage" style={styles.imageColumn}>
                        <img
                            src="/images/bulk email.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

            <section style={styles.section2}>
                <div className="container" style={styles.container}>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>Bulk Email
                            </h1>
                            <p style={styles.description}>

                                TurboMail enables you to send high volumes of emails efficiently and reliably. Whether you’re running a promotional campaign or distributing a newsletter, our platform handles large batches with ease, ensuring your messages reach recipients quickly and in a well-organized manner.
                                <br />
                                <br />
                                Advanced tools help manage sender reputation and compliance with anti-spam regulations to maximize deliverability and minimize bounce rates
                            </p>
                        </div>
                    </div>
                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/bulk email.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>
            </section>


            <section style={styles.firstsection}>
                <div className="container" style={styles.container}>
                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/landing page (3).png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>Landing Pages </h1>
                            <p style={styles.description}>

                                TurboMail allows you to design and launch customized landing pages that align with your email campaigns. These landing pages are crafted to convert visitors into leads or customers by featuring compelling calls-to-action, forms, and engaging content.
                                <br />
                                <br />
                                The integration with your email marketing efforts means you can easily track the performance of these pages, analyze visitor behavior, and make data-driven adjustments to optimize your conversion rates.
                            </p>
                        </div>
                    </div>

                </div>
            </section>


           

            <section style={styles.contactsection}>
                <h1 style={{
                    fontSize: '2rem',
                    marginTop: '60px',
                    color: '#fff',
                    fontWeight: '500',
                    textAlign: 'center'
                }}>Contact us now to start sending emails.</h1>
                <div style={styles.buttondiv}>
                    <button style={styles.heroButton}><Link to="/contact-us" style={{ textDecoration: 'none', color: 'inherit' }}>Contact Us</Link></button>
                </div>
            </section>

        </div>
    );
};


const styles = {

    herocontainer: {
        background: 'linear-gradient(180deg, #0069C8, #000000)',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

    },

    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4.9em',
        fontFamily: 'jaldi',
    },


    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '18px',
        lineHeight: '1.6',
        color: '#7a7a7a',
        fontFamily: '"Jaldi", sans-serif'
    },
    firstsection:{
        backgroundImage: 'url("images/background (4).png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',
        padding:'30px',
    },



    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '50px',
        marginBottom: '50px',
        zIndex: 1,
    },
    herotextColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heroheading: {
        fontSize: '3rem',
        margin: '0 0 20px',
        color: '#fff',
    },
    smallheading: {
        fontSize: '1.3rem',
        color: '#fff'
    },
    herodescription: {
        fontSize: '1.1rem',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#fff',
    },
    textColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#010e37',
        fontWeight: '500'
    },

    description: {
        fontSize: '18px',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#010E37',
        textAlign: 'justify',
    },


    ctaButton: {
        padding: '15px',
        backgroundColor: 'transparent', // Transparent background
        color: '#0069C8', // Text color
        border: '2px solid #0069C8', // Optional border to outline the button
        borderRadius: '100px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
    },
    ctaButtonHover: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Light hover effect
    },
    imageColumn: {
        flex: 1,
        textAlign: 'right',
        marginRight: '80px'
    },
    heroImage: {
        width: '90%',
        
    },


    contactSection: {
        padding: '20px 10px',
        textAlign: 'center',
        backgroundColor: '#fff',
    },
    contactSectionheading: {
        fontSize: '2rem',
        color: '#0069C8',
        fontWeight: '500',
        marginBottom: '30px',
    },
    contactSectioncontainer: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '1100px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    leftColumn: {
        flex: 1,
        backgroundColor: '#0069C8',
        color: '#fff',
        padding: '40px',
        textAlign: 'left',
    },
    rightColumn: {
        flex: 1,
        padding: '40px',
        textAlign: 'left',

    },
    divider: {
        border: '0',
        borderTop: '2px solid #fff',
        width: '50px',
        margin: '10px 0',
    },
    image: {
        height: 'auto',
        margin: '0 auto'
    },
    ul: {
        color: '#1A1A1A',
        marginBottom: '20px',
    }

,


    contactsection: {
        backgroundImage: 'url("images/background (5).png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '100px',
    },
    heroButton: {
        padding: '15px',
        backgroundColor: '#010E37', // Transparent background
        color: '#fff', // Text color
        border: '1px solid #fff', // Optional border to outline the button
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
        zIndex: 1,
        marginTop: '10px',
    },
    buttondiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

export default AboutUs;
