import React from 'react';
import './mobile.css';
import { useState } from 'react';
import Accordion from '../components/accordion'

const AboutUs = () => {

    return (
        <div>

            <section style={styles.hero}>

                <div class="header">
                    <div className="inner-header flex">
                        <h1 style={styles.herocontainerh1}>FAQs</h1>
                    </div>
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>


            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                            These are some of the frequently asked questions that we get about Turbomail. If you have a question that isn’t answered by this list, please contact our team on info@stenciltechnologies.com  </p>
                    </div>
                </div>

            </section>



            <section style={styles.section2}>


                <Accordion />



            </section>

        </div>
    );
};


const styles = {



    container: {
        marginLeft: '5%',
        marginRight: '5%'
    },

    section2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },

    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4.9em',
    },

    description: {
        fontSize: '18px',
    },


    heroButton: {
        padding: '15px',
        backgroundColor: '#010E37', // Transparent background
        color: '#fff', // Text color
        border: '1px solid #fff', // Optional border to outline the button
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
        zIndex: 1,
        marginTop: '10px',
    },
};

export default AboutUs;
