import React, { useState } from 'react';
import './mobile.css';


const ContactUS = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        company: "",
        position: "",
        contactNumber: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can handle the form submission logic here
        console.log("Form Data Submitted:", formData);
    };
    return (
        <div>
            <section style={styles.hero}>

                <div class="header">
                    <div className="inner-header flex">
                        <h1 style={styles.herocontainerh1}>Contact Us</h1>
                    </div>
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>

            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                            Whether you have questions, feedback, or just want to connect, your inquiries are important. Reach out through the options below, and we'll respond promptly. Thank you for visiting — we're excited to hear from you!
                        </p>
                    </div>
                </div>

            </section>
            <div className='mobileform' style={styles.formContainer}>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />
                    <input
                        type="text"
                        name="contactNumber"
                        placeholder="Phone number"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />

                    <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="4"
                        style={styles.textarea}
                    />
                    <button type="submit" style={styles.button}>Submit</button>
                </form>
            </div>

            <section style={styles.container}>

                <div style={styles.contactContainer}>
                    {/* Left Section - Contact Info */}
                    <div className='heroimage' style={styles.formContainer}>
                        <form onSubmit={handleSubmit} style={styles.form}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                            <input
                                type="text"
                                name="contactNumber"
                                placeholder="Phone number"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />

                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                style={styles.textarea}
                            />
                            <button type="submit" style={styles.button}>Submit</button>
                        </form>
                    </div>



                    {/* Right Section - Contact Form */}
                    <div style={styles.contactInfo}>
                        <h3 style={styles.contactHeader}>Contact our skilled IT staff to know more</h3>
                        <hr style={styles.hr} />

                        <ul style={styles.contactList}>
                            <li style={styles.contactItem}>
                                <i className="fas fa-map-marker-alt" style={styles.icon}></i>
                                Unit 4A, Plot 54368, Gaborone
                            </li>
                            <li style={styles.contactItem}>
                                <i className="fas fa-phone-alt" style={styles.icon}></i>
                                +267 3165466
                            </li>
                            <li style={styles.contactItem}>
                                <i className="fas fa-envelope" style={styles.icon}></i>
                                info@stenciltechnologies.com
                            </li>
                        </ul>

                        <h3 style={styles.contactHeader}>Follow Us</h3>
                        <hr style={styles.hr} />
                        <div style={styles.socialIcons}>
                            <a href="https://x.com/StencilTechnol2o"><img src="/images/twitter2.png" alt="Twitter" style={styles.socialIcon} /></a>
                            <a href="https://www.instagram.com/stencil_tech/?igshid=MzRlODBiNWFlZA%3D%3D"><img src="/images/instagram.png" alt="Instagram" style={styles.socialIcon} /></a>
                            <a href="https://www.linkedin.com/company/stencil-technologies/mycompany/"><img src="/images/linkedin.png" alt="LinkedIn" style={styles.socialIcon} /></a>
                            <a href="https://www.facebook.com/StencilTechnologies"><img src="./images/facebook.png" alt="Facebook" style={styles.socialIcon} /></a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};


const styles = {

    herocontainer: {
        backgroundImage: 'url("images/Group 171 (1).png")',
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: '0 auto',

    },

    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4rem',
    },

    socialIcon: {
        width: '30px',
        height: '30px',
      },

    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#010e37',
        fontWeight: '500'
    },

    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },


    contactContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 100,
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '0px',
        marginBottom: '50px',
        zIndex: 1,

    },
    contactInfo: {
        width: '100%',
        padding: '50px'
    },
    contactHeader: {
        fontSize: '24px',
        color: '#010e37',
    },
    hr: {
        border: '1px solid #010e37',
        marginBottom: '10px',
    },
    contactList: {
        listStyle: 'none',
        padding: '0',
    },
    contactItem: {
        marginBottom: '10px',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '10px',
        color: '#1a73e8',
    },
    mapContainer: {
        marginBottom: '20px',
    },
    socialIcons: {
        display: 'flex',
        gap: '10px',
    },
    socialLink: {
        textDecoration: 'none',
        fontSize: '20px',
        color: '#1a73e8',
    },


    formContainer: {
        backgroundColor: "#fff",
        padding: "50px",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        width: "90%",
        height: 'auto',
        // margin: "0 auto",
        textAlign: "center",
        boxSizing: "border-box",
    },

    form: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        width: "100%",
        padding: "15px",
        marginBottom: "15px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        fontSize: "16px",
        boxSizing: "border-box",
    },
    textarea: {
        width: "100%",
        padding: "15px",
        marginBottom: "15px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        fontSize: "16px",
        resize: "vertical",
        boxSizing: "border-box",
    },
    button: {
        padding: "15px",
        backgroundColor: "#010e37",
        color: "white",
        border: "none",
        borderRadius: "5px",
        fontSize: "16px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",

    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // margin: '0 auto',
        paddingTop: '0px',
        marginBottom: '50px',
        zIndex: 1,
    },

};

export default ContactUS;
