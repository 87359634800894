import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/hero';
import About from './pages/about';
import Layout from './components/Layout';
import FAQs from './pages/faqs';
import Features from './pages/features';
import Pricing from './pages/pricing';
import Contactus from './pages/contact-us';
import BookADemo from './pages/book-a-demo';


function App() {
  return (
    <Router>
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/faqs" element={<FAQs />} />
       <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/book-a-demo" element={<BookADemo />} />
       
      </Routes>
    </Layout>
  </Router>
  );
}

export default App;
