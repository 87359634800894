import React from 'react';
import { Link } from 'react-router-dom'; import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './mobile.css';
import { useState } from 'react';

const AboutUs = () => {
    const Accordion = ({ title, content }) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleAccordion = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div style={{ borderRadius: '12px', marginBottom: '10px' }}>
                <div
                    onClick={toggleAccordion}
                    style={{
                        cursor: 'pointer',
                        padding: '10px',
                        backgroundColor: '#010E37',
                        fontWeight: 'bold',
                        borderRadius: '12px',
                    }}
                >
                    {title}
                </div>
                {isOpen && (
                    <div style={{ padding: '10px', backgroundColor: '#fff', color: '#333', border: '1px solid', borderRadius: '12px', }}>
                        {content}
                    </div>
                )}
            </div>
        );
    };


    return (
        <div>

            <section style={styles.hero}>

                <div class="header">
                    <div className="inner-header flex">
                        <h1 style={styles.herocontainerh1}>About Us</h1>
                    </div>
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>


            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                            Welcome to agentBo, your ultimate solution for empowering your business with an unrivaled 24/7 digital omni-channel presence. With seamless integration across popular user channels like WhatsApp, Instagram, Facebook, and more, agentBo, a flagship product of Stencil Technologies' Communications-As-A-Service suite, redefines B2C interactions.    </p>
                    </div>
                </div>

            </section>



            <section style={styles.section2}>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/Turbomail-1-1-1024x599.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>About Turbomail</h1>
                            <p style={styles.description}>

                                Our state-of-the-art technology ensures that your messages reach the right audience at the right time, with features designed to optimize engagement and deliverability. TurboMail offers advanced analytics to track and measure the success of your campaigns, providing insights into open rates, click-through rates, and customer behavior.
                                <br />
                                <br />
                                Our automated workflows streamline your email marketing processes, saving you time and effort by automating repetitive tasks and ensuring timely follow-ups. With personalized content options, you can tailor your messages to individual customer preferences, enhancing relevance and impact. </p>

                        </div>
                    </div>


                </div>

            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                <img
                    src="/images/email about.png"
                    alt="Intro-image"
                    style={styles.heroImage}
                />
            </div>
            <section style={styles.firstsection}>
                <div className="container" style={styles.container}>



                    <div className="textColumn" style={styles.textColumn}>
                        <div style={{ marginRight: '20px', marginLeft: '60px' }}>

                            <p style={styles.description}>With Turbomail, you can say goodbye to complicated email processes and hello to seamless, user-friendly communication. Our platform is designed to streamline your email marketing efforts, making it easier than ever to engage with your audience, promote your brand, and drive results.
                                <br />
                                <br />
                                What sets Turbomail apart is our commitment to innovation and customer satisfaction. We're constantly pushing the boundaries of what's possible in email communication, leveraging the latest technology and industry best practices to deliver a cutting-edge platform that meets the evolving needs of businesses in today's digital landscape. </p>

                        </div>
                    </div>

                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/email about 2.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>

            </section>

            <section style={styles.section2}>
                <h1 style={{ fontSize: '2rem', marginTop: '60px', color: '#010e37', fontWeight: '500', textAlign: 'center' }}>Turbomail's Dedicated Support for Your Email Marketing Journey</h1>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/email about 2.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright" >
                            <p style={styles.description}>
                                Turbomail is more than just a platform—it's a partner in your success. Our dedicated team is here to support you every step of the way, providing personalized assistance and guidance to help you get the most out of your email marketing efforts.
                                <br />
                                <br />
                                Whether you're a small business just getting started or a large enterprise looking to take your email communication to the next level, Turbomail has the solutions you need to succeed. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section style={styles.firstsection}>
                <h1 style={{ fontSize: '2rem', marginTop: '60px', color: '#010e37', fontWeight: '500', textAlign: 'center' }}>Why Use Turbomail</h1>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/email about 3.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright" >
                            <Accordion
                                title="Efficiency"
                                content="Turbomail streamlines your email communication processes, saving you time and effort. With our intuitive platform, you can compose, send, and manage emails more efficiently than ever before."
                            />
                            <Accordion
                                title="Personalization"
                                content="Personalized messaging is crucial for engaging your audience. Turbomail allows you to tailor your messages to each recipient, fostering stronger connections and driving better results."
                            />
                            <Accordion
                                title="Comprehensive Analytics"
                                content="Track the performance of your email campaigns in real-time with Turbomail's comprehensive analytics dashboard. Monitor key metrics such as open rates, click-through rates, bounce rates, and more to gain valuable insights into your campaign performance."
                            />
                            <Accordion
                                title="World-Class Support"
                                content="At Turbomail, we're committed to your success. Our dedicated support team is here to help you every step of the way, whether you have questions about using our platform, need assistance troubleshooting an issue, or want advice on optimizing your email campaigns."
                            />
                        </div>
                    </div>
                </div>
            </section>




        </div>
    );
};


const styles = {

    herocontainer: {
        background: 'linear-gradient(180deg, #0069C8, #000000)',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

    },
    firstsection: {
        backgroundImage: 'url("images/background (4).png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '30px',
    },
    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4em',
    },



    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '18px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },

    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '50px',
        marginBottom: '50px',
        zIndex: 1,
    },
    herotextColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heroheading: {
        fontSize: '3rem',
        margin: '0 0 20px',
        color: '#fff',
    },
    smallheading: {
        fontSize: '1.3rem',
        color: '#fff'
    },
    herodescription: {
        fontSize: '1.1rem',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#fff',
    },
    textColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#010e37',
        fontWeight: '500'
    },

    description: {
        fontSize: '19px',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#010e37',
        textAlign: 'justify',
    },


    imageColumn: {
        flex: 1,
        textAlign: 'right',
        marginRight: '80px'
    },
    heroImage: {
        width: '90%',
        borderRadius: '30px',
    },




};

export default AboutUs;
