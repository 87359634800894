import React, { useState } from 'react';
import { Link } from 'react-router-dom';import { BrowserRouter, Routes, Route } from 'react-router-dom';


import './ResponsiveMenu.css';

const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = (menu) => {
        setShowDropdown(showDropdown === menu ? null : menu);
    };

    return (
        <nav className="navbar">
            <Link to="/" ><img className="navbar-brand"
                src="./images/turbomail logo.png"
                alt="Logo-image"
            /></Link>


            <div className="hamburger" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
            <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                <li><Link to="/" >Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/features" >Our Features</Link></li>
                <li><Link to="/pricing" >Pricing</Link></li>
                <li><Link to="/faqs" >FAQs</Link></li>
                <li><Link to="/contact-us" >Contact Us</Link></li>
                <li><Link to="/book-a-demo" >Book a Demo</Link></li>

                <button className='navbutton'>
                    <a href="https://stenciltechnologies.everlytic.net/" >Log in</a>
                </button>

            </ul>

        </nav>
    );
};

export default Menu;
