import React from 'react';
import './mobile.css';
import { Link } from 'react-router-dom';

const Hero = () => {
    return (
        <div>

            <section style={styles.hero}>

                <div class="header2">
                    <div style={styles.herocontainer}>

                        {/* Left column (text) */}
                        <div className="herotextColumn" style={styles.herotextColumn}>
                            <h1 style={styles.heroheading}>Instant B2C Email Connections.</h1>
                            <h1 style={styles.heroheading2}>Effortless Messaging!</h1>
                            <p style={styles.herodescription}>
                                We revolutionize email communication with a user-friendly platform for personalized messaging. Swift connections with customers are enabled, while voice capability and diverse communication options allow businesses to multicast updates, promote their brand, and foster genuine connections.
                            </p>

                            <button style={styles.heroButton}><Link to="/book-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Book a demo</Link></button>
                        </div>

                        {/* Right column (image) */}
                        <div className="heroimage" style={styles.imageColumn}>
                            <img
                                src="images/Turbomail-1-1-1024x599.png"
                                alt="Intro-image"
                                style={styles.heroImage}
                            />
                        </div>
                    </div>
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>


            <section style={styles.section2}>

                <h1 style={{
                    fontSize: '2rem',
                    marginTop: '60px',
                    color: '#010e37',
                    fontWeight: '500',
                    textAlign: 'center'
                }}>How Turbomail can help you.</h1>


                <div className="container" style={styles.container}>
                    <div className="textColun" >
                        <p style={styles.description1}>
                            At Stencil Technologies, we understand the importance of effective communication in today's fast-paced business world. That's why we have developed a powerful platform to streamline your email communication processes and help you achieve your business goals more efficiently than ever before. Here's how Turbomail can help you:
                        </p>
                        {/* <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button> */}

                    </div>
                </div>
            </section>
            <section style={styles.firstsection}>

                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="images/streamlined-comminication-1024x758.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginleft" >
                            <h1 style={styles.heading}>Streamlined Communication</h1>
                            <p style={styles.description}>
                                Turbomail provides a user-friendly platform for personalized messaging, allowing you to communicate with your customers seamlessly and swiftly. Say goodbye to complicated email processes and hello to effortless communication. </p>
                            {/* <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button> */}
                        </div>
                    </div>
                </div>


            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                <img
                    src="images/diverce coms.png"
                    alt="Intro-image"
                    style={styles.heroImage}
                />
            </div>
            <section style={styles.section4}>

                <div className="container" style={styles.container}>
                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright" >
                            <h1 style={styles.heading}>Diverse Communication Options</h1>
                            <p style={styles.description}>
                                Turbomail caters to diverse email communication needs with its voice capability. Whether you need to multicast important updates, promote your brand, or establish genuine connections through personalized messaging, Turbomail has you covered.
                            </p>
                            {/* <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button> */}
                        </div>
                    </div>

                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="images/diverce coms.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>

            </section>
            <section style={styles.firstsection}>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="images/email-eficiency-1024x842.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginleft" >
                            <h1 style={styles.heading}>Efficiency and Productivity</h1>
                            <p style={styles.description}>
                                By simplifying your email communication processes, Turbomail helps you save time and boost productivity. With fewer manual tasks to worry about, you can focus on what matters most – growing your business.
                            </p>
                            {/* <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button> */}
                        </div>
                    </div>
                </div>

            </section>



            <section style={styles.section6}>


              
                <div className="container" style={styles.container}>

                    <div className="textColun" >
                        <h1 style={{
                            fontSize: '2rem',
                            marginTop: '60px',
                            color: '#010e37',
                            fontWeight: '500',
                            textAlign: 'center'
                        }}>Benefits of Turbomail</h1>

                        <h3 style={{
                            fontWeight: '500',
                            fontSize: '1.5rem', color: '#010e37', textAlign: 'center'
                        }}>Use Our Drag and Drop Email Builder</h3>
                        <p style={styles.description1}>
                            Easily craft stunning and fully responsive Email templates with our intuitive Drag-and-Drop email template builder. Say goodbye to the complexities of HTML and CSS – even without any prior coding experience, you can effortlessly create visually captivating emails that adapt seamlessly across all devices.
                        </p>
                        {/* <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button> */}

                    </div>

                </div>
                <div className="mobileimage" style={styles.imageColumn}>
                    <img
                        src="images/drag and drop.png"
                        alt="Intro-image"
                        style={styles.heroImage}
                    />
                </div>
            </section>
            <section style={styles.section7}>
                <div className="container" style={styles.container}>
                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">

                            <ul style={styles.ul}>
                                <li style={styles.li}>1. Effortlessly craft and design emails in minutes.</li>
                                <li style={styles.li}>2. Choose from a wide selection of templates suited to your preferences.</li>
                                <li style={styles.li}>3. Experience responsive design options.</li>
                                <li style={styles.li}>4. No need for design or coding proficiency.</li>
                                <li style={styles.li}>5. Add your personal touch with text, images, and more!</li>
                                <li style={styles.li}>6. Freedom to upload your own templates.</li>
                            </ul>

                        </div>
                    </div>

                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="images/drag and drop.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>

            </section>
            <section style={styles.firstsection}>

                <h1 style={{
                    fontSize: '2rem',
                    marginTop: '60px',
                    color: '#010e37',
                    fontWeight: '500',
                    textAlign: 'center'
                }}>Set-Up Email Automations</h1>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="images/campaing-image-2-904x1024.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginleft" >

                            <p style={styles.description}>
                                Schedule messages for optimal timing, strategically introducing delays to engage clients effortlessly and boost sales automatically.
                                <br />
                                <br />
                                Craft intelligent customer journeys by triggering and adjusting communication according to client data or message interaction for personalized engagement.
                            </p>
                            {/* <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button> */}
                        </div>
                    </div>
                </div>

            </section>
            <section style={styles.section7}>
                <h1 style={{
                    fontSize: '2rem',
                    marginTop: '60px',
                    color: '#010e37',
                    fontWeight: '500',
                    textAlign: 'center'
                }}>Create Simple Campaign Workflows</h1>
                <div className="container" style={styles.container2}>

                    <img
                        src="images/campaing-image-1-1-858x1024.png"
                        alt="Intro-image"
                        style={styles.heroImage2}
                    />
                </div>
            </section>
            <section style={styles.contactsection}>
                <h1 style={{
                    fontSize: '2rem',
                    marginTop: '60px',
                    color: '#fff',
                    fontWeight: '500',
                    textAlign: 'center'
                }}>Contact us now to start sending emails.</h1>
                <div style={styles.buttondiv}>
                    <button style={styles.heroButton}><Link to="/contact-us" style={{ textDecoration: 'none', color: 'inherit' }}>Contact Us</Link></button>
                </div>
            </section>
        </div>
    );
};


const styles = {
    hero: {
        position: 'relative',
        background: 'linear-gradient(180deg, #010e37, #606060)',
        textAlign: 'left',
        overflow: 'hidden',
    },
    firstsection: {
        backgroundImage: 'url("images/background (4).png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '30px',
    },

    contactsection: {
        backgroundImage: 'url("images/background (5).png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '100px',
    },
    buttondiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    section2: {
        position: 'relative',
        paddingBottom: '20px',
    },
    section3: {
    },
    section4: {
        position: 'relative',
        paddingBottom: '20px',
    },
    section5: {
        position: 'relative',
        paddingBottom: '20px',
    },
    section6: {
        position: 'relative',
        paddingBottom: '20px',
    },
    section7: {
        position: 'relative',
        paddingBottom: '20px',
    },


    herocontainer: {

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '50px',
        position: 'relative',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '50px',
        marginBottom: '50px',
        zIndex: 1,
    },

    container2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '50px',
        marginBottom: '50px',
        zIndex: 1,
    },
    herotextColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heroheading: {
        margin: '0 0 20px',
        color: '#fff',
        fontSize: '4rem'
    },

    heroheading2: {
        margin: '0 0 20px',
        color: '#00BCD4',
        fontSize: '2rem'
    },
    smallheading: {
        fontSize: '1.3rem',
        color: '#fff'
    },
    herodescription: {
        fontSize: '1.1rem',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#fff',
    },
    textColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#010e37',
        fontWeight: '500'
    },

    description: {
        fontSize: '18px',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#010E37',
    },

    description1: {
        fontSize: '18px',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#7a7a7a',
        textAlign: 'center',
    },

    heroButton: {
        padding: '15px',
        backgroundColor: '#010E37', // Transparent background
        color: '#fff', // Text color
        border: '1px solid #fff', // Optional border to outline the button
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
        zIndex: 1,
        marginTop: '10px',
    },

    imageColumn: {
        flex: 1,
        textAlign: 'right',
    },
    heroImage: {
        width: '90%',

    },
    heroImage2: {
        width: '60%',

    },

    contactcolor: {
        color: '#fff'
    },

    waveDivider: {
        position: 'absolute',
        bottom: -5,
        left: 0,
        width: '127%',
        overflow: 'hidden',
        lineHeight: 0,
        zIndex: 0,
    },
    waveSvg: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    contactSection: {
        padding: '20px 10px',
        textAlign: 'center',
        backgroundColor: '#fff',
    },
    contactSectionheading: {
        fontSize: '2rem',
        color: '#0069C8',
        fontWeight: '500',
        marginBottom: '30px',
    },
    contactSectioncontainer: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '1100px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    leftColumn: {
        flex: 1,
        backgroundColor: '#0069C8',
        color: '#fff',
        padding: '40px',
        textAlign: 'left',
    },
    rightColumn: {
        flex: 1,
        padding: '40px',
        textAlign: 'left',

    },
    divider: {
        border: '0',
        borderTop: '2px solid #fff',
        width: '50px',
        margin: '10px 0',
    },
    image: {
        height: 'auto',
        margin: '0 auto'
    },
    imgrightColumn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ul: {
        listStyle: 'none'
    },

    li: {
        color: '#000',
        margin: '20px'
    }
};

export default Hero;
