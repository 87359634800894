import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  footer: {
    backgroundColor: '#000',
    color: '#fff',
    padding: '20px 10px',
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap', // Allows wrapping on smaller screens
    justifyContent: 'space-around',
    gap: '20px', // Space between sections
  },
  footerSection: {
    flex: '1 1 200px', // Grow, shrink, and set min width for responsiveness
    padding: '10px',
    minWidth: '150px', // Ensure a minimum width for small screens
  },
  heading: {
    color: '#fff',
    marginBottom: '15px',
    fontSize: '28px',
    fontWeight: 'bold',
    fontFamily: '"Jaldi", sans-serif',
    
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    margin: '5px 0',
     fontFamily: '"Jaldi", sans-serif',
     fontSize: '20px',
     
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  logo: {
    width: '60%',
    height: 'auto',
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px', // Space between social icons
  },
  socialIcon: {
    width: '30px',
    height: '30px',
  },
  hover: {
    textDecoration: 'underline',
  },

  copyright:{
    backgroundColor: '#000',
    color: '#fff',
    padding: '20px 10px',
    textAlign: 'center',
     fontFamily: '"Jaldi", sans-serif',
  },
  // Media query for small screens
  '@media (max-width: 600px)': {
    footer: {
      flexDirection: 'column', // Stack sections vertically on small screens
      textAlign: 'center',
    },
    footerSection: {
      padding: '10px 0',
    },
  },
};

// Footer component
const Footer = () => {
  return (
    <footer >
      {/* Product Section */}
      <div style={styles.footer}>
      <div style={styles.footerSection}>
        <h3 style={styles.heading}> A Product Of </h3>

        <Link to="/" style={styles.link}> <img
          src="./images/stencil-logos-02-1-1-qbdoob825580i3dn0j01wtnopb4anxu4xzkjc6l0bg (1).png" // replace with the actual logo path
          alt="Stencil Logo"
          style={styles.logo}

        /></Link>
      </div>

      {/* Important Links Section */}
      <div style={styles.footerSection}>
        <h3 style={styles.heading}> Quick Links </h3>
        <ul style={styles.list}>
  
          <li style={styles.listItem}><Link to="/about" style={styles.link}>About</Link></li>
          <li style={styles.listItem}><Link to="/features" style={styles.link}>Our Features</Link></li>
          <li style={styles.listItem}><Link to="/pricing" style={styles.link}>Pricing</Link></li>
          <li style={styles.listItem}><Link to="/faqs" style={styles.link}>FAQs</Link></li>
        </ul>
      </div>

      {/* Contact Section */}
      <div style={styles.footerSection}>
        <h3 style={styles.heading}> Contact Us </h3>
        <ul style={styles.list}>
          <li style={styles.listItem}>+267 3165 466</li>
          <li style={styles.listItem}><a href="mailto:info@stenciltechnologies.com" style={styles.link}>info@stenciltechnologies.com</a></li>
        </ul>
      </div>

      {/* Social Media Section */}
      <div style={styles.footerSection}>
        <h3 style={styles.heading}> Our Social Media </h3>
        <div style={styles.socialIcons}>
          <a href="https://x.com/StencilTechnol2"><img src="/images/twitter2.png" alt="Twitter" style={styles.socialIcon} /></a>
          <a href="https://www.instagram.com/stencil_tech/?igshid=MzRlODBiNWFlZA%3D%3D"><img src="/images/instagram.png" alt="Instagram" style={styles.socialIcon} /></a>
          <a href="https://www.linkedin.com/company/stencil-technologies/mycompany/"><img src="/images/linkedin.png" alt="LinkedIn" style={styles.socialIcon} /></a>
          <a href="https://www.facebook.com/StencilTechnologies"><img src="./images/facebook.png" alt="Facebook" style={styles.socialIcon} /></a>
        </div>
      </div>
      </div>
      <div style={styles.copyright}>
        <p> © Copyright 2024, All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
