import React, { useRef, useState } from 'react'
import { RiArrowDropDownLine } from 'react-icons/ri'

import './accordion.css';

//  accordionitem component
  const data = [
    {
     question: 'Q. How long does it take to setup an account with Turbomail?',
     answer: 'Our implementation time is 3 – 5 working days. If you need any custom integrations done, this can take a little longer.' ,
    },
    {
     question: 'Q. Do you offer a free trial?',
     answer: 'No, we don’t offer a free trial version of Turbomail.',
    },
    {
     question: 'Q. Does Turbomail do social media management?',
     answer: 'No, we don’t manage your social media accounts.',
    },
    {
     question: 'Do you create content or draft email marketing strategies for your customers?',
     answer: 'No, we are not a marketing agency. Turbomail offers you a powerful platform for content sharing, but we don’t create it for you.',
    },
    {
     question: 'Q. Is Turbomail a CRM?',
     answer: 'Turbomail is not a CRM, but we can integrate with just about any CRM, CMS, or ERP platform.',
    }
   ];
  

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  const contentHeight = useRef()
   return(
     <div className="wrapper" >
     <button className={`question-container ${isOpen ? 'active' : ''}`} onClick={onClick} >
      <p className='question-content'>{question}</p>
      <RiArrowDropDownLine className={`arrow ${isOpen ? 'active' : ''}`} /> 
     </button>
 
      <div ref={contentHeight} className="answer-container" style={
           isOpen
           ? { height: contentHeight.current.scrollHeight }
           : { height: "0px" }
          }>
       <p className="answer-content">{answer}</p>
      </div>
    </div>
   )
 }
// main Accordion component
const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
 
  const handleItemClick = (index) => {
   setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
 
  return (
   <div className='container'>
     {data.map((item, index) => (
     <AccordionItem
      key={index}
      question={item.question}
      answer={item.answer}
      isOpen={activeIndex === index}
      onClick={() => handleItemClick(index)}
     />
    ))}
   </div>
  )
 };

export default Accordion;