import React from 'react';
// import Header from './header';
import Footer from './footer';
import Menu from './header'

const Layout = ({ children }) => {
  return (
    <div>
      {/* <Header /> */}
      <Menu/>
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;