import React from 'react';
import { Link } from 'react-router-dom';
import './mobile.css';
import './pricing.css';

const Pricing = () => {



    return (
        <div>

            <section style={styles.hero}>

                <div class="header">
                    <div className="inner-header flex">
                        <h1 style={styles.herocontainerh1}>Pricing</h1>
                    </div>
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>

            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                            We offer a range of pricing plans designed to fit businesses of all sizes. Whether you’re a startup looking to scale your communication or an established enterprise needing robust email solutions, we have a plan for you.</p>
                    </div>
                </div>

            </section>



            <section style={styles.section2}>

                {/* <div class="container">
                    <div class="top">
                        <div class="package-container">
                            <div class="packages">
                                <h1>Basic</h1>
                                <ul class="list">
                                    <li class="first">2000 Subscribers</li>
                                    <li>12,000 Emails/month</li>
                                    <li>Multi-User Accounts</li>
                                    <li>Email Support</li>
                                </ul>
                            </div>
                            <div class="packages">
                                <h1>Professional</h1>
                                <ul class="list">
                                    <li class="first">Basic +</li>
                                    <li>Landing Pages</li>
                                    <li>Pop-up Forms</li>
                                    <li>Premium Support</li>
                                </ul>
                            </div>
                            <div class="packages">
                                <h1>Master</h1>
                                <ul class="list">
                                    <li class="first">Professional +</li>
                                    <li>Marketing Automation</li>
                                    <li>Instagram Ads</li>
                                    <li>Facebook Ads</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="card-container">
                    <div class="rate-card">
                        <div class="header-row">
                            <div class="header-feature">Features</div>
                            <div class="header-package basic">Basic<br />P 2,021 per month</div>
                            <div class="header-package professional">Professional<br />P 5,499 per month</div>
                            <div class="header-package advanced">Advanced<br />P 10,992 per month</div>
                            <div class="header-package private-security">Private Security<br />P 40,274 per month</div>
                        </div>

                        <div class="row">
                            <div class="feature-title">Once-Off Setup Fee</div>
                            <div class="package-detail">P 2,021</div>
                            <div class="package-detail">P 5,499</div>
                            <div class="package-detail">P 5,499</div>
                            <div class="package-detail">P 40,274</div>
                        </div>

                        <div class="section-title">The Basics</div>
                        <div class="row">
                            <div class="feature-title">Subscriber Limit</div>
                            <div class="package-detail">Up to 250 000</div>
                            <div class="package-detail">Unlimited</div>
                            <div class="package-detail">Unlimited</div>
                            <div class="package-detail">Unlimited</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Tenants Included</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">2</div>
                            <div class="package-detail">3</div>
                            <div class="package-detail">3</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Multi-Tenant: P 2 245 / Tenant</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Custom Branding / White Labeling</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>

                        <div class="section-title">Support & Advice</div>
                        <div class="row">
                            <div class="feature-title">Email & Telephone Support</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Client Relationship Manager & Strategic Advice / Reports</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Reputation Management</div>
                            <div class="package-detail">Basic</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>

                        <div class="section-title">Data & Encryption</div>
                        <div class="row">
                            <div class="feature-title">Secure Server & Encryption</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Custom SSL Certificate</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Custom Password Policy</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Private Database Backups & Policies</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Private Encryption Keys</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Private Server & Database Hosting</div>
                            <div class="package-detail"></div>
                            <div class="package-detail"></div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="section-title">Create & Publish / Sending</div>
                        <div class="row">
                            <div class="feature-title">Email Campaigns & SMS Campaigns</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">A/B Split Test Email</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Landing Pages & Subscription Forms</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Workflows & Automation</div>
                            <div class="package-detail">Basic*</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Voice Broadcasting</div>
                            <div class="package-detail"></div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Email & SMS Attachments</div>
                            <div class="package-detail"></div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Staggered Sending & Rate Limiting</div>
                            <div class="package-detail"></div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>

                        <div class="section-title">Reports</div>
                        <div class="row">
                            <div class="feature-title">Advanced Campaign Reports</div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Google Analytics Integration</div>
                            <div class="package-detail"></div>
                            <div class="package-detail"></div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>

                        <div class="section-title">Transactional</div>
                        <div class="row">
                            <div class="feature-title">Email & SMS History</div>
                            <div class="package-detail">12 months</div>
                            <div class="package-detail">12 months</div>
                            <div class="package-detail">12 months</div>
                            <div class="package-detail">12 months</div>
                        </div>

                        <div class="section-title">Training</div>
                        <div class="row">
                            <div class="feature-title">Bi-annual Group Training at Stencil Technologies Offices</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                            <div class="package-detail">&#10004;</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">1:1 Training at Stencil Technologies Offices</div>
                            <div class="package-detail">P 3 354 per Session</div>
                            <div class="package-detail">2 x Free Sessions</div>
                            <div class="package-detail">4 x Free Sessions</div>
                            <div class="package-detail">5 x Free Sessions</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">1:1 Training at Customer Premises</div>
                            <div class="package-detail">P 5 583.50 per Session</div>
                            <div class="package-detail">P 5 583.50 per Session</div>
                            <div class="package-detail">P 5 583.50 per Session</div>
                            <div class="package-detail">P 5 583.50 per Session</div>
                        </div>
                        <div class="row">
                            <div class="feature-title">Beginner or Advanced Online Training</div>
                            <div class="package-detail">P 3 354 per Session</div>
                            <div class="package-detail">P 3 354 per Session</div>
                            <div class="package-detail">P 3 354 per Session</div>
                            <div class="package-detail">P 3 354 per Session</div>
                        </div>

                    </div>
                </div>
            </section >


            <section style={styles.contactsection}>
                <h1 style={{
                    fontSize: '2rem',
                    marginTop: '60px',
                    color: '#fff',
                    fontWeight: '500',
                    textAlign: 'center'
                }}>Contact us now to start sending emails.</h1>
                <div style={styles.buttondiv}>
                    <button style={styles.heroButton}><Link to="/contact-us" style={{ textDecoration: 'none', color: 'inherit' }}>Contact Us</Link></button>
                </div>
            </section>


        </div >
    );
};


const styles = {

    herocontainer: {
        background: 'linear-gradient(180deg, #0069C8, #000000)',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

    },

    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4.9em',
        fontFamily: 'jaldi',
    },

    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },

    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '0px',
        marginBottom: '50px',
        zIndex: 1,
    },
    section2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    contactsection: {
        backgroundImage: 'url("images/background (5).png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '100px',
    },

    heroButton: {
        padding: '15px',
        backgroundColor: '#010E37', // Transparent background
        color: '#fff', // Text color
        border: '1px solid #fff', // Optional border to outline the button
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
        zIndex: 1,
        marginTop: '10px',
    },

    buttondiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

};

export default Pricing;
