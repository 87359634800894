import React, { useState } from 'react';
import './mobile.css';


const BookADemo = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        company: "",
        position: "",
        contactNumber: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can handle the form submission logic here
        console.log("Form Data Submitted:", formData);
    };
    return (
        <div>
            <section style={styles.hero}>

                <div class="header">
                    <div className="inner-header flex">
                        <h1 style={styles.herocontainerh1}>Book A Demo</h1>
                    </div>
                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>

            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                        Ready to experience the full power of TurboMail? Book a demo with our team and discover how our platform can transform your email marketing strategy.During the demo, you’ll get a personalized walkthrough of our features, see real-time examples, and have the opportunity to ask any questions you may have.

Schedule your demo today and take the first step towards more effective and engaging email communication.

    </p>
                    </div>
                </div>

            </section>

            <section style={styles.section2}>
                <div className="container" style={styles.container}>
                    <div style={styles.formContainer}>
                        <form onSubmit={handleSubmit} style={styles.form}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                            <input
                                type="text"
                                name="contactNumber"
                                placeholder="Phone number"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />

                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                style={styles.textarea}
                            />
                            <button type="submit" style={styles.button}>Submit</button>
                        </form>
                    </div>
                </div>
            </section>

        </div>
    );
};


const styles = {



    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4rem',
    },

    socialIcon: {
        width: '30px',
        height: '30px',
    },

section2:{
    display: 'flex',
    justifyContent: 'center',
},

    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#010e37',
        fontWeight: '500'
    },

    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },




    hr: {
        border: '1px solid #010e37',
        marginBottom: '10px',
    },


    formContainer: {
        backgroundColor: "#fff",
        padding: "50px",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        width: "100%",
        height: 'auto',
        margin: "0 auto",
        textAlign: "center",
        boxSizing: "border-box",
    },

    form: {
        display: "flex",
        flexDirection: "column",
        marginLeft: '20px'
    },
    input: {
        width: "100%",
        padding: "15px",
        marginBottom: "15px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        fontSize: "16px",
        boxSizing: "border-box",
    },
    textarea: {
        width: "100%",
        padding: "15px",
        marginBottom: "15px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        fontSize: "16px",
        resize: "vertical",
        boxSizing: "border-box",
    },
    button: {
        padding: "15px",
        backgroundColor: "#010e37",
        color: "white",
        border: "none",
        borderRadius: "5px",
        fontSize: "16px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",

    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '50px',
        width:'80%'
    },

};

export default BookADemo;
